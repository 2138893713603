import React, { useContext } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { StateContext } from '../App';
import '../styles/styles.css';
import dufryCancun from '../images/dufry-logo-web-cancun.png';

export default function Navbar() {
  const { tema } = useContext(StateContext);
  const [site] = Object.values(useParams());
  const { pathname } = useLocation();
  return (
    <>
      <div className="nav">
        <div className="navLeft">
          <Link to={`/${site}`}>
            <img
              className="nflLogo"
              src={tema.logo}
              alt=""
            />
          </Link>
        </div>
        <div className="navRight">
          {!pathname.includes('admin') && (
            <img
              className="dufryLogo"
              src={dufryCancun}
              alt="dufry"
            />
          )}
        </div>
      </div>
    </>
  );
}
