import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import QuestionsPage from './QuestionsPage';
import ResultsPage from './ResultsPage';
import AdminImages from './AdminImages';
import SectionsEditor from './SectionsEditor';
import FormBuilder from './FormBuilder';

const AdminRoutes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/(cancun)/admin"
        to="/(cancun)/admin/questions"
      />
      <Route
        path="/(cancun)/admin/questions"
        component={QuestionsPage}
      />
      <Route path="/(cancun)/admin/users" component={ResultsPage} />
      <Route
        path="/(cancun)/admin/secciones"
        component={SectionsEditor}
      />
      <Route
        path="/(cancun)/admin/formulario"
        component={FormBuilder}
      />
      <Route
        exact
        path="/(cancun)/admin/imagenes"
        component={AdminImages}
      />
    </Switch>
  );
};

export default AdminRoutes;
