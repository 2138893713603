class Storage {
  setItem(key, value) {
    key = btoa(key);

    if (value instanceof Object) {
      value = JSON.stringify(value);
      value = Buffer.from(value).toString('base64');
    } else {
      value = btoa(value);
    }

    localStorage.setItem(key, value);

    return value;
  }

  getItem(key) {
    key = btoa(key);
    let value = localStorage.getItem(key);

    if (value !== null) {
      try {
        const base64 = Buffer.from(value, 'base64').toString('utf8');
        value = JSON.parse(base64);
      } catch (e) {
        value = atob(value);
      }
    }

    return value;
  }

  removeItem(key) {
    key = btoa(key);
    const value = this.getItem(key);

    localStorage.removeItem(key);

    return value;
  }
}

const storage = new Storage();

export { storage };
