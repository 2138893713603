import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import '../styles/styles.css';

import { StateContext } from '../App';
import { storage } from '../storage';

export default function Inicio() {
  const [site] = Object.values(useParams());
  const handleLanguage = l => {
    storage.setItem('language', l);
  };
  const { tema } = useContext(StateContext);

  return (
    <div
      className="bg-main"
      style={{ backgroundImage: `url("${tema.languageImage}")` }}
    >
      <Navbar />
      <div className="lang">
        <h2>Choose your Language | Escoge tu Idioma</h2>
        <section className="languages">
          <div>
            <p>ENGLISH</p>
            <Link
              to={`/${site}/instructions`}
            >
              <img
                className="flags"
                src={require('../images/flag-us.jpg')}
                alt="language"
                onClick={() => handleLanguage('en')}
              />
            </Link>
          </div>
          <div>
            <p>ESPAÑOL</p>
            <Link to={`/${site}/instrucciones`}>
              <img
                className="flags"
                src={require('../images/flag-mx.png')}
                alt="language"
                onClick={() => handleLanguage('es')}
              />
            </Link>
          </div>
        </section>
      </div>
    </div>
  );
}
