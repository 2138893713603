import React from 'react';
import { Spin } from 'antd';

const Loader = () => {
  return (
    <section
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        minHeight: '300px'
      }}
    >
      <Spin></Spin>
    </section>
  );
};

export default Loader;
