import React, { useReducer, useEffect, useState } from 'react';
import {
  Table,
  Divider,
  Icon,
  Button,
  Form,
  Input,
  Select,
  message
} from 'antd';
import Loader from '../Loader';
import { getFormFields, setFormFields } from '../../firebase';

const { Option } = Select;

const reducer = (state, action) => {
  const { value, index, key, id } = action.payload;

  switch (action.type) {
    case 'INITIAL_LOAD':
      return action.payload;

    case 'ADD_NEW':
      return [
        ...state,
        {
          id,
          unique_key: '',
          label_en: '',
          label_es: '',
          type: 'text'
        }
      ];

    case 'UPDATE':
      return state.reduce((acc, element, currentIndex) => {
        const el =
          currentIndex === index ? { ...element, [key]: value } : element;
        return [...acc, el];
      }, []);

    case 'MOVE_LEFT':
      return [
        ...state.slice(0, index - 1),
        state[index],
        state[index - 1],
        ...state.slice(index + 1)
      ];

    case 'MOVE_RIGHT':
      return [
        ...state.slice(0, index),
        state[index + 1],
        state[index],
        ...state.slice(index + 2)
      ];

    case 'REMOVE':
      return [...state.slice(0, index), ...state.slice(index + 1)];

    default:
      return state;
  }
};

const FormBuilder = () => {
  const [state, dispatch] = useReducer(reducer, undefined);
  const [pristine, setPristine] = useState(true);

  useEffect(() => {
    loadFirebaseFields();
  }, []);

  const loadFirebaseFields = () => {
    getFormFields()
      .then(fieldsData => {
        const fields = fieldsData ? fieldsData : { data: [] };

        dispatch({
          type: 'INITIAL_LOAD',
          payload: fields.data
        });
      })
      .catch(e => console.log(e));
  };

  const updateFirebaseFields = () => {
    const hideLoadingMessage = message.loading(
      'Actualizando información de formulario',
      0
    );

    setFormFields(state)
      .then(() => {
        setPristine(true);
        hideLoadingMessage();
        message.success('Actualizado correctamente');
      })
      .catch(e => {
        hideLoadingMessage();
        message.error('Algo salio mal, por favor intente de nuevo mas tarde');
        console.log(e);
      });
  };

  const updateInput = (value, index, key) => {
    setPristine(false);
    dispatch({ type: 'UPDATE', payload: { value, index, key } });
  };
  const addInput = () => {
    const id = 'DUFRY_' + Date.now() + Math.random() * 10;
    setPristine(false);
    dispatch({ type: 'ADD_NEW', payload: { id } });
  };
  const removeInput = index => {
    setPristine(false);
    dispatch({ type: 'REMOVE', payload: { index } });
  };
  const moveInputUp = index => {
    setPristine(false);
    dispatch({ type: 'MOVE_LEFT', payload: { index } });
  };
  const moveInputDown = index => {
    setPristine(false);
    dispatch({ type: 'MOVE_RIGHT', payload: { index } });
  };

  const validateNotEmpty = (key, value) => {
    return value !== '' ? 'success' : 'error';
  };
  const validateUnique = (key, value) => {
    return value !== '' &&
      state.filter(element => element[key] === value).length === 1
      ? 'success'
      : 'error';
  };

  const ValidateForm = () => {
    if (pristine) {
      return pristine;
    }

    const formIsValid = state.every(field => {
      return (
        validateUnique('unique_key', field.unique_key) === 'success' &&
        validateNotEmpty('label_en', field.label_en) === 'success' &&
        validateNotEmpty('label_es', field.label_es) === 'success'
      );
    });

    return !formIsValid;
  };

  const columns = [
    {
      title: 'Identificador único',
      dataIndex: '',
      key: 'a',
      render: (_, __, index) => (
        <Form.Item
          validateStatus={validateUnique('unique_key', state[index].unique_key)}
          help="Requerido. Único."
        >
          <Input
            value={state[index].unique_key}
            onChange={({ target }) =>
              updateInput(target.value, index, 'unique_key')
            }
          />
        </Form.Item>
      )
    },
    {
      title: 'Etiqueta en ingles',
      dataIndex: '',
      key: 'b',
      render: (_, __, index) => (
        <Form.Item
          validateStatus={validateNotEmpty('label_en', state[index].label_en)}
          help="Requerido"
        >
          <Input
            value={state[index].label_en}
            onChange={({ target }) =>
              updateInput(target.value, index, 'label_en')
            }
          />
        </Form.Item>
      )
    },
    {
      title: 'Etiqueta en español',
      dataIndex: '',
      key: 'c',
      render: (_, __, index) => (
        <Form.Item
          validateStatus={validateNotEmpty('label_es', state[index].label_es)}
          help="Requerido"
        >
          <Input
            value={state[index].label_es}
            onChange={({ target }) =>
              updateInput(target.value, index, 'label_es')
            }
          />
        </Form.Item>
      )
    },
    {
      title: 'Tipo de valor',
      dataIndex: '',
      key: 'd',
      render: (_, __, index) => (
        <Select
          value={state[index].type}
          onChange={value => updateInput(value, index, 'type')}
        >
          <Option value="text">Text</Option>
          <Option value="number">Number</Option>
          <Option value="phone">Phone</Option>
          <Option value="email">Email</Option>
          <Option value="checkbox">Checkbox</Option>
          <Option value="ticket">Ticket</Option>
        </Select>
      )
    },
    {
      title: 'Acciones',
      dataIndex: '',
      key: 'e',
      render: (_, __, index) => {
        return (
          <>
            <Button type="link" onClick={() => removeInput(index)}>
              <Icon type="delete" theme="twoTone" twoToneColor="#eb2f96" />
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={index === 0}
              onClick={() => moveInputUp(index)}
            >
              <Icon type="caret-up" />
            </Button>
            <Button
              type="link"
              disabled={index === state.length - 1}
              onClick={() => moveInputDown(index)}
            >
              <Icon type="caret-down" />
            </Button>
          </>
        );
      }
    }
  ];

  if (!state) {
    return <Loader></Loader>;
  }

  return (
    <>
      <section style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={addInput}>Agregar un nuevo campo</Button>
        <Button disabled={ValidateForm()} onClick={updateFirebaseFields}>
          Guardar cambios
        </Button>
      </section>
      <Form>
        <Table
          rowKey="id"
          pagination={false}
          columns={columns}
          dataSource={state}
        />
      </Form>
    </>
  );
};

export default FormBuilder;
