import React, { useEffect, useState, useContext } from 'react';
import { getUsers, fire } from '../../firebase';
import * as XLSX from 'xlsx';
import { Table, Button } from 'antd';

import { StateContext } from '../../App';

const ResultsPage = ({ history }) => {
  const { form } = useContext(StateContext);
  const [logged, setLogged] = useState(false);
  const [users, setUsers] = useState([]);

  // Redirects user if not logged
  useEffect(() => {
    fire.auth().onAuthStateChanged((u) => {
      if (!u) {
        history.push(`/`);
      } else {
        getFUsers();
        setLogged(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Retrieves user responses
  const getFUsers = () => {
    getUsers()
      .then((r) => {
        const users = r.map((item) => {
          for (const key in item) {
            if (typeof item[key] === 'boolean') {
              item[key] = item[key].toString();
            }
          }

          return item;
        });

        setUsers(users);
      })
      .catch((e) => console.log(e));
  };

  const downloadResults = () => {
    // Prepares information
    const headerObject = {
      answers: 'answers',
      country: 'country',
      date: 'date',
      email: 'email',
      id: 'id',
      name: 'name',
      phone: 'phone',
      possible: 'possible',
      privacy: 'privacy',
      rights: 'rights',
      site: 'site',
      terms: 'terms',
      ticket: 'ticket',
      time: 'time',
    };
    const headers = Object.keys(headerObject);
    const items = users.map((item) => {
      const localItem = { ...item };

      for (const key in localItem) {
        if (!headers.includes(key)) {
          delete localItem[key];
        }
      }

      return localItem;
    });

    // Creates xlsx file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(
      [headerObject, ...items],
      {
        headers,
        skipHeader: true,
      }
    );

    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      'Hoja de calculo 1'
    );
    XLSX.writeFile(workbook, `Resultados.xlsx`);
  };

  // Table column names and order
  const tableColumns = form
    ? form.map((field) => {
        return {
          title: field.label_es,
          dataIndex: field.unique_key,
          key: field.unique_key,
        };
      })
    : [];

  if (!logged) return <p>You need to be logged</p>;

  return (
    <div>
      <Button disabled={users.length === 0} onClick={downloadResults}>
        Descargar resultados
      </Button>

      <Table
        rowKey="id"
        dataSource={users}
        columns={tableColumns}
        pagination={false}
      />
    </div>
  );
};

export default ResultsPage;
