import React, { useContext } from 'react';
import Navbar from '../components/Navbar';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'antd';
import '../styles/styles.css';
import { StateContext } from '../App';

export default function Instrucciones() {
  const { tema, sections } = useContext(StateContext);
  const [site] = Object.values(useParams());
  return (
    <div
      className="instrucciones"
      style={{ backgroundImage: `url("${tema.instructionsImage}")` }}
    >
      <Navbar />
      <section className="boxInstruc">
        {sections.es && (
          <article
            dangerouslySetInnerHTML={{
              __html: sections.es.instructions[site],
            }}
          ></article>
        )}

        <Link to={`/${site}/formulario`}>
          <Button className="botonJugar" type="primary">
            Jugar
          </Button>
        </Link>
        {sections.es && (
          <article
            className="footerNotice"
            dangerouslySetInnerHTML={{
              __html: sections.es.footer[site],
            }}
          ></article>
        )}
      </section>
    </div>
  );
}
