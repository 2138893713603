import React, { useReducer, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import ReactQuill from "react-quill";
import { Tabs, Button, Collapse, Input, Upload, Select, message } from "antd";

import { getSections, setSections } from "../../firebase";
import Loader from "../Loader";
import "react-quill/dist/quill.snow.css";
import "../../styles/editor.css";

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Option } = Select;

const SectionsEditor = () => {
  const [site] = Object.values(useParams());
  const [pristine, setPristine] = useState(true);
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "INITIAL_LOAD":
        return action.payload;

      case "UPDATE":
        if (pristine) {
          setPristine(false);
        }

        const { key, value, language, site } = action.payload;
        const languageState = state[language] ? state[language] : {};
        const sectionState = state[language][key] ? state[language][key] : {};
        return {
          ...state,
          [language]: {
            ...languageState,
            [key]: {
              ...sectionState,
              [site]: value,
            },
          },
        };

      default:
        return state;
    }
  }, {});

  useEffect(() => {
    loadFirebaseSections();
  }, []);

  const loadFirebaseSections = () => {
    getSections().then((response) => {
      dispatch({
        type: "INITIAL_LOAD",
        payload: response,
      });
    });
  };

  const updateFirebaseSections = () => {
    const hideLoadingMessage = message.loading("Actualizando secciones", 0);
    setSections(state, site)
      .then(() => {
        setPristine(true);
        hideLoadingMessage();
        message.success('Actualizado correctamente');
      })
      .catch((e) => {
        hideLoadingMessage();
        message.error(
          'Algo salio mal, por favor intente de nuevo mas tarde'
        );
        console.log(e);
      });
  };

  const updateSections = (key, value, language, site) => {
    dispatch({
      type: "UPDATE",
      payload: {
        key,
        value,
        language,
        site
      },
    });
  };

  const formValidation = () => {
    const validateNumber = (value) => {
      const min = 5;
      const max = 20;
      const number = parseInt(value, 10);

      return !isNaN(number) && number >= min && number <= max;
    };

    const validNumberOfQuestions =
      validateNumber(state.en.questions[site]) && validateNumber(state.es.questions[site]);

    return pristine || !validNumberOfQuestions;
  };

  if (Object.keys(state).length === 0) {
    return <Loader></Loader>;
  }

  return (
    <section className="sections-editor">
      <Button
        disabled={formValidation()}
        onClick={updateFirebaseSections}
      >
        Guardar cambios
      </Button>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Español" key="1">
          <Editors
            language="es"
            values={state.es}
            onChange={updateSections}
            site={site}
          />
        </TabPane>
        <TabPane tab="English" key="2">
          <Editors
            language="en"
            values={state.en}
            onChange={updateSections}
            site={site}
          />
        </TabPane>
      </Tabs>
    </section>
  );
};

const emptyFn = () => {};
const secondsInMinute = new Array(60).fill(null).map((_, i) => i + 1);

const Editors = ({ language = "en", values = {}, onChange = emptyFn, site }) => {
  const labels = {
    questions: {
      en: {
        header: "Total questions",
        body: "How many questions should the user see?",
      },
      es: {
        header: "Total de preguntas",
        body: "¿Cuantas preguntas debería ver el usuario?",
      },
    },
    secondsPerQuestion: {
      en: {
        header: "Seconds between questions",
        body: "How many seconds should answer opportunities last?",
        unit: "seconds",
      },
      es: {
        header: "Segundos entre preguntas",
        body: "¿Cuántos segundos deben durar las oportunidades de respuesta?",
        unit: "segundos",
      },
    },
    ticket: {
      en: {
        header: "Ticket example",
        link: "Link text",
        button: "Example image",
      },
      es: {
        header: "Ejemplo de ticket",
        link: "Texto del enlace",
        button: "Imagen de ejemplo",
      },
    },
    instructions: { en: "Instructions", es: "Instrucciones" },
    privacy: { en: "Privacy policy", es: "Política de privacidad" },
    terms: { en: "Terms and conditions", es: "Términos y condiciones" },
    footer: { en: "Footer notice", es: "Pie de pagina" },
  };

  const uploadProps = {
    beforeUpload: (file) => {
      const hideLoadingMessage = message.loading("Procesando imagen", 0);
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        hideLoadingMessage();
        onChange("ticketImage", reader.result, language, site);
      };
      reader.onerror = () => {
        hideLoadingMessage();
        message.error("Algo salio mal, por favor intente de nuevo mas tarde");
      };
    },
    showUploadList: false,
  };

  return (
    <section>
      <Collapse defaultActiveKey="1" accordion>
        <Panel header={labels.questions[language].header} key="1">
          <article style={{ padding: '15px' }}>
            <Input
              type="number"
              min={5}
              max={20}
              value={values.questions[site]}
              onChange={({ target }) =>
                onChange('questions', target.value, language, site)
              }
              addonBefore={labels.questions[language].body}
            />
          </article>
        </Panel>

        <Panel
          header={labels.secondsPerQuestion[language].header}
          key="2"
        >
          <article style={{ padding: '15px' }}>
            <Input.Group compact>
              <Input
                disabled
                style={{
                  width: '75%',
                  color: 'rgba(0, 0, 0, 0.65)',
                  cursor: 'auto',
                }}
                value={labels.secondsPerQuestion[language].body}
              />
              <Select
                style={{ width: '25%' }}
                defaultValue={values.secondsPerQuestion[site]}
                onChange={(value) => {
                  onChange('secondsPerQuestion', value, language, site);
                }}
              >
                {secondsInMinute.map((second) => (
                  <Option key={second} value={second}>
                    {second.toString().padStart(2, '00')}{' '}
                    {labels.secondsPerQuestion[language].unit}
                  </Option>
                ))}
              </Select>
            </Input.Group>
          </article>
        </Panel>

        <Panel header={labels.ticket[language].header} key="3">
          <div
            style={{
              display: 'flex',
              padding: '15px',
            }}
          >
            <article style={{ flex: 1 }}>
              <Input
                type="text"
                value={values.ticketLink[site]}
                onChange={({ target }) =>
                  onChange('ticketLink', target.value, language, site)
                }
                addonBefore={labels.ticket[language].link}
              />
            </article>
            <article>
              <Upload {...uploadProps}>
                <Button>{labels.ticket[language].button}</Button>
              </Upload>
            </article>
          </div>
        </Panel>

        <Panel header={labels.instructions[language]} key="4">
          <WYSIWYG
            value={values.instructions[site]}
            change={(value) =>
              onChange('instructions', value, language, site)
            }
          />
        </Panel>

        <Panel header={labels.privacy[language]} key="5">
          <WYSIWYG
            value={values.privacy[site]}
            change={(value) => onChange('privacy', value, language, site)}
          />
        </Panel>

        <Panel header={labels.terms[language]} key="6">
          <WYSIWYG
            value={values.terms[site]}
            change={(value) => onChange('terms', value, language, site)}
          />
        </Panel>

        <Panel header={labels.footer[language]} key="7">
          <WYSIWYG
            value={values.footer[site]}
            change={(value) => onChange('footer', value, language, site)}
          />
        </Panel>
      </Collapse>
    </section>
  );
};

const WYSIWYG = ({ value, change = emptyFn }) => {
  return (
    <ReactQuill
      placeholder='Write something...'
      defaultValue={value}
      onChange={change}
    />
  );
};

export default SectionsEditor;
